<!-- 事件上报详情 -->
<template>
  <div class="container eventReporting-warp flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/sjsb.png')" alt="" />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img
            :src="require('@/assets/image/sjicon.png')"
            class="img-icon"
          /><span>事件上报详情</span>
        </div>
      </div>
    </div>
    <div class="panel-warp flexone">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">事件名称</div>
          <div class="panel-item-right bra3 panel-item-block plr">{{forms.name}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">事件类型</div>
          <div class="panel-item-right bra3 panel-item-block plr">{{ $dt.changeCodeName('event_type',  forms.type )}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">事件等级</div>
          <div class="panel-item-right bra3 panel-item-block plr">
             <van-radio-group
                v-model="forms.level"
                checked-color="rgb(255, 77, 79)"
                direction="horizontal"
                   class="dis-radio red"
                icon-size="16px"
                disabled
              >
                <van-radio :name="item.value" v-for="(item,index) in event_level" :key="index">{{item.text}}</van-radio>
              </van-radio-group>
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">上报时间</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{$dt.formatDate(forms.time)}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            事件地点
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{forms.areaAllName}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            事件说明
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{forms.info}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">照片</div>
          <div
            class="panel-item-right bra3 panel-item-block fill panel-img-warp"
          >
            <img
               v-for="(item,index) in forms.photos"
              :key="index"
              :src="item"
              class="panel-img-rec"
              alt=""
              @click="handlePreImg(forms.photos,index)"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">视频</div>
          <div
            class="panel-item-right bra3 panel-item-block fill panel-img-warp"
          >
          <div class="panel-img-rec video-bg-warp" v-for="(item, index) in forms.videos"      @click="handlePreVideo(item)" :key="index">
            <img  src="../../assets/image/shipin-copy.png" />
          </div>
            <!-- <video
              v-for="(item,index) in forms.videos"
              :key="index"
              :src="item"
              class="panel-img-rec"
              @click="handlePreVideo(item)"
            ></video> -->
          </div>
        </li>
        <template v-if="forms.state == 1">
          <li>
            <div class="panel-item-left bra3 panel-item-block">处理人员</div>
            <div class="panel-item-right bra3 plr panel-item-block">{{forms.hdl_by}}</div>
          </li>
          <li>
            <div class="panel-item-left bra3 panel-item-block">处理人电话</div>
            <div class="panel-item-right bra3 plr panel-item-block">{{forms.hdl_phone}}</div>
          </li>
          <li>
            <div class="panel-item-left bra3 panel-item-block f-s12">
              最迟处理时间
            </div>
            <div class="panel-item-right bra3 plr panel-item-block">{{$dt.formatDate(forms.deadline)}}</div>
          </li>

          <li>
            <div class="panel-item-left bra3 panel-item-block">处理时间</div>
            <div class="panel-item-right bra3 plr panel-item-block">{{$dt.formatDate(forms.hdl_at)}}</div>
          </li>
          <li>
            <div class="panel-item-left bra3 panel-item-block">是否超时</div>
            <div class="panel-item-right bra3 panel-item-block fill">
              <div class="d-f-s">
                <div class="panel-item-block plr br2 flex1">{{forms.timeout === 0 ? '否':'是'}}</div>
                <div class="panel-item-block plr br2 flex1">超时时间</div>
                <div class="panel-item-block plr flex1">{{forms.timeout ? forms.timeout + '天': ''}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="panel-item-left bra3 panel-item-block align-flex-top">
              处理说明
            </div>
            <div class="panel-item-right bra3 plr panel-item-block">
              {{forms.hdl_info}}
            </div>
          </li>
          <li>
            <div class="panel-item-left bra3 panel-item-block">处理照片</div>
            <div
              class="panel-item-right bra3 panel-item-block fill panel-img-warp"
            >
              <img
                v-for="(item,index) in forms.hdlPhotos"
                :key="index"
                :src="item"
                class="panel-img-square"
                alt=""
                @click="handlePreImg(forms.hdlPhotos,index)"
              />
            </div>
          </li>
          <li>
            <div class="panel-item-left bra3 panel-item-block">处理视频</div>
            <div
              class="panel-item-right bra3 panel-item-block fill panel-img-warp"
            >
            <div class="panel-img-rec video-bg-warp" v-for="(item, index) in forms.hdlVideos"      @click="handlePreVideo(item)" :key="index">
              <img  src="../../assets/image/shipin-copy.png" />
            </div>
            <!-- <video
                v-for="(item,index) in forms.hdlVideos"
                :key="index"
                :src="item"
                class="panel-img-rec"
                @click="handlePreVideo(item)"
              ></video> -->
            </div>
          </li>
        </template>
      </ul>
      <van-image-preview v-model="show" closeable :images="preImages" :start-position="startIndex"> 
      </van-image-preview>
      <van-dialog
        style="width:100%;border-radius:0;height:200px"
        theme="default"
        v-model="showvideoplay"
        :show-cancel-button="false"
        :show-confirm-button="false"
        closeOnClickOverlay
      >
        <video
          controls
          preload="auto"
          style="width:100%;height:200px;object-fit: contain;"
          :src="videourl"
          playsinline 
          x5-video-player-type="h5" 
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint" 
          x-webkit-airplay="true"
          v-if="videourl"
        ></video>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/detailMixin";
import { mapState } from "vuex";
export default {
  mixins: [mixin],
  data() {
    return {
      interfaceUrl: "/api/event/get",
      images: [require("@/assets/image/home1.png")],

    };
  },
  computed:{
    ...mapState(["event_level","event_type"]),
  },
  mounted() {},
  methods: {
  },
};
</script>

<style lang="less">
.eventReporting-warp {
  letter-spacing: 0px;
  position: relative;
  overflow: auto;
}
</style>
